import { loginOrSignUpCheckedEmailStorageKey } from "~/utils/loginSignup/loginSignup"
import { appPaths } from "~/utils/paths/paths"

export default defineNuxtRouteMiddleware(async (to, from) => {
  const nuxtApp = await useNuxtApp()
  const checkedEmail = await useCookie(loginOrSignUpCheckedEmailStorageKey)
  if (checkedEmail?.value) {
    return true
  }

  return nuxtApp.runWithContext(() => navigateTo(appPaths.loginSignup))
})
